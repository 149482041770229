import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import htb_alejandro from "../../images/contents/htb-alejandro.jpg"
import htb_alex from "../../images/contents/htb-alex.jpg"
import htb_antonieta from "../../images/contents/htb-antonieta.jpg"
import htb_darcy from "../../images/contents/htb-darcy.jpg"
import htb_dominika from "../../images/contents/htb-dominika.jpg"
import htb_fernando from "../../images/contents/htb-fernando.jpg"
import htb_jonathan from "../../images/contents/htb-jonathan.jpg"
import htb_mariana from "../../images/contents/htb-mariana.jpg"
import htb_marieli from "../../images/contents/htb-marieli.jpg"
import htb_mauricio from "../../images/contents/htb-mauricio.jpg"
import htb_ramiro from "../../images/contents/htb-ramiro.jpg"
import htb_veronica from "../../images/contents/htb-veronica.jpg"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="main maker">

          <Header />

          <div className="content">

            <h2 className="title">HEDDING<br/>to beauty</h2>

            <div className="maker_wrapper">
              <div className="maker_container"><img key="308638541" src={htb_alejandro} className="maker_image" video="https://vimeo.com/308638541" onClick={this.imageClick} alt="308638541"/></div>
              <div className="maker_container"><img key="308638738" src={htb_alex} className="maker_image" video="https://vimeo.com/308638738" onClick={this.imageClick} alt="308638738"/></div>
              <div className="maker_container"><img key="308638912" src={htb_antonieta} className="maker_image" video="https://vimeo.com/308638912" onClick={this.imageClick} alt="308638912"/></div>
              <div className="maker_container"><img key="308639098" src={htb_darcy} className="maker_image" video="https://vimeo.com/308639098" onClick={this.imageClick} alt="308639098"/></div>
              <div className="maker_container"><img key="308639382" src={htb_dominika} className="maker_image" video="https://vimeo.com/308639382" onClick={this.imageClick} alt="308639382"/></div>
              <div className="maker_container"><img key="308639642" src={htb_fernando} className="maker_image" video="https://vimeo.com/308639642" onClick={this.imageClick} alt="308639642"/></div>
              <div className="maker_container"><img key="308639857" src={htb_jonathan} className="maker_image" video="https://vimeo.com/308639857" onClick={this.imageClick} alt="308639857"/></div>
              <div className="maker_container"><img key="309105703" src={htb_mariana} className="maker_image" video="https://vimeo.com/309105703" onClick={this.imageClick} alt="309105703"/></div>
              <div className="maker_container"><img key="309106904" src={htb_marieli} className="maker_image" video="https://vimeo.com/309106904" onClick={this.imageClick} alt="309106904"/></div>
              <div className="maker_container"><img key="309107328" src={htb_mauricio} className="maker_image" video="https://vimeo.com/309107328" onClick={this.imageClick} alt="309107328"/></div>
              <div className="maker_container"><img key="309108399" src={htb_ramiro} className="maker_image" video="https://vimeo.com/309108399" onClick={this.imageClick} alt="309108399"/></div>
              <div className="maker_container"><img key="309110793" src={htb_veronica} className="maker_image" video="https://vimeo.com/309110793" onClick={this.imageClick} alt="309110793"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;