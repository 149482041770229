import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './scss/main.scss';
import * as serviceWorker from './serviceWorker';

import Home from './pages/Home';
import Filmmakers from './pages/Filmmakers';
import Producer from './pages/Producer';
import Planner from './pages/Planner';
import Services from './pages/Services';
import Contents from './pages/Contents';

import Agustin from './pages/makers/Agustin';
import Alex from './pages/makers/Alex';
import David from './pages/makers/David';
import Krist from './pages/makers/Krist';
import Leo from './pages/makers/Leo';
import Homies from './pages/makers/Homies';
import MarioGomez from './pages/makers/MarioGomez';
import MarioZavala from './pages/makers/MarioZavala';
import Martin from './pages/makers/Martin';
import Rafa from './pages/makers/Rafa';
import Roby from './pages/makers/Roby';
import Toledo from './pages/makers/Toledo';

import VivaBonito from './pages/contents/VivaBonito';
import Dermaheal from './pages/contents/Dermaheal';
import Soriana from './pages/contents/Soriana';
import Gflac from './pages/contents/Gflac';
import Hedding from './pages/contents/Hedding';
import VidaFitness from './pages/contents/VidaFitness';
import SistemaPTX from './pages/contents/SistemaPTX';

function Paths() {
  return (

    <Router>

        <Switch>
          
          <Route exact path="/" component={Home} />
          
          <Route exact path="/filmmakers/" component={Filmmakers} />



          <Route exact path="/filmmakers/agustin/" component={Agustin} />          

          <Route exact path="/filmmakers/alex/" component={Alex} />

          <Route exact path="/filmmakers/david/" component={David} />

          <Route exact path="/filmmakers/krist/" component={Krist} />

          <Route exact path="/filmmakers/leo/" component={Leo} />

          <Route exact path="/filmmakers/homies/" component={Homies} />

          <Route exact path="/filmmakers/mario_gomez/" component={MarioGomez} />

          <Route exact path="/filmmakers/mario_zavala/" component={MarioZavala} />

          <Route exact path="/filmmakers/martin/" component={Martin} />

          <Route exact path="/filmmakers/rafa/" component={Rafa} />

          <Route exact path="/filmmakers/roby/" component={Roby} />

          <Route exact path="/filmmakers/toledo_zavala/" component={Toledo} />


          
          <Route exact path="/producer/" component={Producer} />

          <Route exact path="/planner/" component={Planner} />

          <Route exact path="/services/" component={Services} />

          <Route exact path="/contents/" component={Contents} />
          
          <Route exact path="/contents/vivabonito/" component={VivaBonito} />
          
          <Route exact path="/contents/dermaheal/" component={Dermaheal} />
          
          <Route exact path="/contents/soriana/" component={Soriana} />
          
          <Route exact path="/contents/gflac/" component={Gflac} />
          
          <Route exact path="/contents/hedding/" component={Hedding} />
          
          <Route exact path="/contents/vidafitness/" component={VidaFitness} />
          
          <Route exact path="/contents/ptx/" component={SistemaPTX} />

          <Route render={() => <h2>404 Page Not Found</h2>} />

        </Switch>

    </Router>

  );
}

ReactDOM.render(<Paths />, document.getElementById('root'));

serviceWorker.unregister();
