import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import vf1 from "../../images/contents/vf1.jpg"
import vf2 from "../../images/contents/vf2.jpg"
import vf3 from "../../images/contents/vf3.jpg"
import vf4 from "../../images/contents/vf4.jpg"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="main maker">

          <Header />

          <div className="content">

            <h2 className="title">VIDA<br/>FITNESS</h2>

            <div className="maker_wrapper">
              <div className="maker_container"><img key="309122936" src={vf1} className="maker_image" video="https://vimeo.com/309122936" onClick={this.imageClick} alt="309122936"/></div>
              <div className="maker_container"><img key="309125482" src={vf2} className="maker_image" video="https://vimeo.com/309125482" onClick={this.imageClick} alt="309125482"/></div>
              <div className="maker_container"><img key="309127916" src={vf3} className="maker_image" video="https://vimeo.com/309127916" onClick={this.imageClick} alt="309127916"/></div>
              <div className="maker_container"><img key="309128688" src={vf4} className="maker_image" video="https://vimeo.com/309128688" onClick={this.imageClick} alt="309128688"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;